import { gql } from '@apollo/client';
import {
  VehicleFieldsFull,
  VehicleInventoryRows,
} from './data-layer/fragments';

export * from './data-layer/comments';
export * from './data-layer/vehicle';

export const FETCH_TYPEAHEAD_FOR_VEHICLE_TYPE = gql`
  query GetTypeaheadsForVehicleType(
    $field: String!
    $order: OrderBy!
    $filters: FilterType!
  ) {
    getTypeaheadsForVehicleType(
      field: $field
      order: $order
      filters: $filters
    ) {
      typeaheads {
        field
        values
      }
    }
  }
`;

export const FETCH_TAGS_LIST = gql`
  query GetTagList($id: String!, $tagExpirationDate: Float) {
    getTagList(id: $id, tagExpirationDate: $tagExpirationDate) {
      id
      expirationDate
      aOrB
      tagActivityTypeCode
    }
  }
`;

export const FETCH_TYPEAHEAD = gql`
  query GetTypeaheads(
    $model: String
    $field: String!
    $order: OrderBy!
    $filters: [Filter!]!
  ) {
    getTypeaheads(
      model: $model
      field: $field
      order: $order
      filters: $filters
    ) {
      typeaheads {
        field
        values
      }
    }
  }
`;

export const GET_MAKE_OPTIONS = gql`
  query GetMakeNames {
    getMakeNames {
      makeCode
      makeName
    }
  }
`;

export const GET_VEHICLE_EDIT_OPTIONS = gql`
  query GetVehicleMakeModels($makeName: String!) {
    getVehicleMakeModels(makeName: $makeName) {
      models {
        modelCode
        makeCode
        modelDescription: modelName
        modelTypeCode
      }
      make {
        makeCode
        makeName
      }
      colors {
        makeCode
        makeColorCode
        name
      }
    }
    getFuelNames {
      id
      description
    }
  }
`;

export const GET_VEHICLE_FILTERABLE_YEARS = gql`
  query GetVehicleFilterableYears($makeCode: String) {
    getVehicleFilterableYears(makeCode: $makeCode) {
      years
    }
  }
`;
export const GET_VEHICLE_FILTERABLE_MAKES = gql`
  query GetVehicleFilterableMakes {
    getVehicleFilterableMakes {
      makes {
        makeCode
        makeName
      }
    }
  }
`;
export const GET_VEHICLE_FILTERABLE_MODELS_BY_MAKE = gql`
  query GetVehicleFilterableModelsByMake($makeCode: String!) {
    getVehicleFilterableModelsByMake(makeCode: $makeCode) {
      models {
        modelCode
        makeCode
        modelDescription: modelName
      }
    }
  }
`;

export const GET_AGENCIES = gql`
  query GetAgencies($order: OrderBy) {
    getAgencies(order: $order) {
      id
      name
    }
  }
`;

export const GET_REPORT_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission(
    $operation: String!
    $subject: String!
    $feature: String
    $order: OrderBy
  ) {
    getAgenciesByPermission(
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name: prefixedName
    }
  }
`;

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission(
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getAgenciesByPermission(
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
    }
  }
`;

export const GET_AGENCY = gql`
  query GetAgency($id: String!) {
    getAgency(id: $id) {
      id
      name
      shortName
      isDOD
      bureaus {
        id
        name
      }
    }
  }
`;

export const GET_RECALL_CAMPAIGN = gql`
  query GetRecallCampaign($campaignId: String!) {
    getRecallCampaign(campaignId: $campaignId) {
      id
      recallCode
      recallDescription
      detailDescription
      safetyRisk
      nhtsaCampaignId
      recallType
      recallUrl
      isRemedyAvailable
      remedyDescription
      shouldStopDrive
      shouldParkOutside
      recallSource
      createdAt
      make {
        makeCode
        makeName
      }
      recallReleaseDate
      gsaNotifiedDate
    }
  }
`;

export const REMOVE_VIN_FROM_CAMPAIGN = gql`
  mutation RemoveVinFromCampaign($campaignId: String!, $assetId: String!) {
    removeVinFromCampaign(campaignId: $campaignId, assetId: $assetId)
  }
`;

export const GET_RECALL_DETAILS = gql`
  query GetRecallCampaignDetails(
    $order: OrderBy
    $limit: Float!
    $offset: Float!
    $campaignId: String!
  ) {
    getRecallCampaignDetails(
      order: $order
      limit: $limit
      offset: $offset
      campaignId: $campaignId
    ) {
      rows {
        id
        recallCampaignId
        assetId
        status
        userCloseStatus
        closedDate
        userClosedDate
        gsaRecallNotifiedDate
        closedByUser
        createdBy
        createdAt
        updatedBy
        updatedAt
        deletedAt
        vehicle {
          id
          tagNumber
          ownershipTypeCode
          vin
        }
        recallCampaign {
          id
          recallCode
          recallSource
          recallType
          isRemedyAvailable
          recallReleaseDate
          gsaNotifiedDate
          recallUrl
          recallDescription
          remedyStatus
          createdBy
          createdAt
          updatedBy
          updatedAt
          deletedAt
        }
      }
      count
      hasMore
    }
  }
`;

export const UPDATE_OR_CREATE_ASSET_DISPOSAL = gql`
  mutation UpdateOrCreateDisposal($assetDisposalArg: AssetDisposalArg!) {
    updateOrCreateDisposal(assetDisposalArg: $assetDisposalArg) {
      assetId
      disposalProceeds
      acquisitionCost
      disposalCost
      actualDisposalReason
      actualDisposalDate
      salvagePercent
      usefulLifeMiles
      usefulLifeMonths
      capitalizedItem
      capitalValue
      bookValue
      depreciationCumulative
      maximumRepairAmount
      originalAcquisitionDate
      eligibleReplacementDate
      turnInCode
      turnInLocation
      disposalCondition
      salvageValue
      forecastedResidualValue
      depreciableUsefulLife
      disposalNumber
    }
  }
`;

export const UPDATE_LEASING_RATES = gql`
  mutation UpdateLeasingRate($leasingRate: LeasingRateUpdateRequest!) {
    updateLeasingRate(leasingRate: $leasingRate) {
      assetId
      manualEntryRate
      monthlyRate
      mileageRate
      dailyRate
      optionalEquipmentRate
      afvSurcharge
      modifiedMileageRateType
      highCostThreshold
      location
      startDate
      leaseRateCode
    }
  }
`;

export const GET_LEASING_RATES = gql`
  query GetLeasingRate($leasingRateRequest: LeasingRateRequest!) {
    getLeasingRate(leasingRateRequest: $leasingRateRequest) {
      assetId
      manualEntryRate
      monthlyRate
      dailyRate
      mileageRate
      optionalEquipmentRate
      afvSurcharge
      modifiedMileageRateType
      highCostThreshold
      location
      optInSubscriptionRate
      startDate
      leaseRateCode
    }
  }
`;

export const GET_ASSET_DISPOSAL = gql`
  query getDisposalByAssetId($assetId: String!) {
    getDisposalByAssetId(assetId: $assetId) {
      assetId
      disposalProceeds
      acquisitionCost
      disposalCost
      actualDisposalReason
      actualDisposalDate
      salvagePercent
      usefulLifeMiles
      usefulLifeMonths
      capitalizedItem
      capitalValue
      bookValue
      depreciationCumulative
      maximumRepairAmount
      originalAcquisitionDate
      turnInCode
      turnInLocation
      eligibleReplacementDate
      disposalCondition
      fairMarketValue
      salvageValue
      forecastedResidualValue
      depreciableUsefulLife
      isResidualValueComplete
      disposalNumber
    }
  }
`;

export const GET_BUREAUS = gql`
  query GetBureaus($agencyCode: String) {
    getBureaus(agencyCode: $agencyCode) {
      agencyCode
      id
      name
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
      prefixedName
    }
  }
`;

export const GET_BUREAU = gql`
  query GetBureau($id: String!, $agencyCode: String!) {
    getBureau(id: $id, agencyCode: $agencyCode) {
      id
      name
      shortName
      prefixedName
      agency {
        id
        prefixedName
      }
    }
  }
`;

export const UPDATE_ORDER_AGENCY = gql`
  mutation UpdateOrderAgencyDetails(
    $orderNumber: String!
    $agencyDetailsData: AgencyDetailsArgs!
  ) {
    updateOrderAgencyDetails(
      orderNumber: $orderNumber
      agencyDetailsData: $agencyDetailsData
    ) {
      orderNumber
    }
  }
`;

export const UPDATE_ORDER_BUREAU = gql`
  mutation UpdateOrderBureau($orderNumber: String!, $bureauCode: String!) {
    updateBureau(orderNumber: $orderNumber, bureauCode: $bureauCode) {
      bureau {
        id
        name
      }
    }
  }
`;

export const GET_OFFICES = gql`
  query GetOffices($agencyCode: String!, $bureauCode: String!) {
    getOffices(agencyCode: $agencyCode, bureauCode: $bureauCode) {
      id
      name
    }
  }
`;

export const GET_OFFICE_NEXT = gql`
  query GetOfficeNext(
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
  ) {
    getOfficeNext(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      agencyCode
      officeCode
      officeName
    }
  }
`;

export const GET_OFFICES_NEXT = gql`
  query GetOfficesNext($agencyCode: String, $bureauCode: String) {
    getOfficesNext(agencyCode: $agencyCode, bureauCode: $bureauCode) {
      agencyCode
      officeCode
      officeName
    }
  }
`;

export const GET_OFFICES_BY_PERMISSION = gql`
  query GetOfficesByPermission(
    $agencyCode: String!
    $bureauCode: String!
    $subject: String!
    $operation: String!
    $order: OrderBy
    $feature: String
  ) {
    getOfficesByPermission(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      subject: $subject
      operation: $operation
      order: $order
      feature: $feature
    ) {
      agencyCode
      officeCode
      officeName
      prefixedName
    }
  }
`;

export const GET_CUSTOMER_ACCOUNT_FOR_REPORTS = gql`
  query GetCustomerAccountForReports(
    $agencyCode: String!
    $bureauCode: String!
  ) {
    getCustomerAccountForReports(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
    )
  }
`;

export const GET_ALL_OFFICES = gql`
  query GetOfficesNext($agencyCode: String!, $bureauCode: String!) {
    getOfficesNext(agencyCode: $agencyCode, bureauCode: $bureauCode) {
      officeCode
      officeName
    }
  }
`;

export const UPDATE_ORDER_OFFICE = gql`
  mutation UpdateOffice($orderNumber: String!, $officeCode: String!) {
    updateOffice(orderNumber: $orderNumber, officeCode: $officeCode) {
      office {
        officeCode
        officeName
      }
    }
  }
`;

export const UPDATE_ORDER_POC = gql`
  mutation UpdateOrderPoc($orderNumber: String!, $orderPoc: OrderPocArgs!) {
    updateOrderPoc(orderNumber: $orderNumber, orderPoc: $orderPoc) {
      orderNumber
      pocName
      pocEmail
      pocPhone
      agencyAddress
    }
  }
`;

export const GET_POC = gql`
  query GetPoC($id: String!) {
    getPoC(id: $id) {
      email
      firstName
      lastName
      primaryPhone
      primaryPhoneExt
      primaryAddress
      secondaryAddress
      city
      postalCode
      stateCode
      countryCode
    }
  }
`;

export const GET_SUPPORTING_ATTACHMENTS = gql`
  query GetSupportingAttachments(
    $filters: FilterType
    $order: OrderBy
    $offset: Float
    $limit: Float
  ) {
    getSupportingAttachments(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        modelId
        metadataId
        documentType
        documentName
        model
        metadata {
          name
          description
          docSource
          docStoreUri
          fileLocation
          fileMimeType
          scanStatus
          size
          uploadCompletedAt
          creator {
            fullName
          }
        }
        createdAt
        createdByUser {
          fullName
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_TAG = gql`
  query GetTag($id: String!, $tagExpirationDate: Float) {
    getTag(id: $id, tagExpirationDate: $tagExpirationDate) {
      uuid
      id
      counterPartTag {
        uuid
        aOrB
        tagActivityTypeCode
        unicorTag {
          uuid
          orderNumber
        }
      }
      expirationDate
      aOrB
      customerPointOfContactName
      customerPointOfContactEmailAddress
      customerPointOfContactPhone
      customerPointOfContactAddress1
      customerPointOfContactAddress2
      customerPointOfContactAddress3
      customerPointOfContactAddress4
      customerPointOfContactCity
      customerPointOfContactState
      customerPointOfContactZip
      tagActivityTypeCode
      createdBy
      updatedAt
      updatedBy
      updatedByUser {
        fullName
        email
      }
      activity {
        typeCode
        activityType {
          description
        }
      }
      agency {
        id
        name
      }
      bureau {
        id
        name
      }
      office {
        officeName
        officeCode
      }
      unicorTag {
        uuid
        orderNumber
      }
      vehicle {
        id: vin
        tag {
          id
          expirationDate
        }
        modelCode
        tagNew {
          id
          expirationDate
        }
        assetLifecycle {
          lifeCycle {
            lifecycleIndicator
          }
        }
        itemInventoryStatusCode
        inventoryStatus {
          description
        }
        modelYear
        vehicleMake {
          makeName
        }
        vehicleModel {
          modelDescription: modelName
        }
        makeColor {
          name
        }
        fuel {
          description
        }
        fastReportable
        ownershipTypeCode
        customer {
          customerPhysicalOffice {
            officeCode
            officeName
          }
        }
      }
      comments {
        count
      }
      tagLocation {
        locationName
        address1
        address2
        address3
        city
        stateCode
        postalCode
        countryCode
      }
    }
    getSupportingAttachments(
      filters: {
        operator: "AND"
        value: [{ operator: "EQ", key: "modelId", value: $id }]
      }
    ) {
      count
    }
  }
`;

export const GET_TAG_ACTIVITY = gql`
  query GetTag($id: String!) {
    getTag(id: $id) {
      expirationDate
      customerAgencyCode
      customerBureauCode
      customerSubSectionCode
      activity {
        activityType {
          typeCode
        }
      }
    }
  }
`;

export const DISACCOCIATE_TAG = gql`
  mutation DisassociateTag($tag: TagDisassociationArgs!) {
    disassociateTag(tag: $tag) {
      id
      tagActivityTypeCode
      aOrB
      VIN
      vehicle {
        id
      }
    }
  }
`;

export const GET_TAGS_LOST_STOLEN = gql`
  query GetTagsLostStolen($tagNumber: String!) {
    getTagsLostStolen(tagNumber: $tagNumber) {
      tagId
      stolenDate
      gsaReportedDate
      dhsReportedDate
      dhsCaseNumber
      ncicNumber
      comment
    }
  }
`;

export const CREATE_OR_UPDATE_MISSING_TAG = gql`
  mutation CreateOrUpdateTagLostStolen(
    $tagLostStolenInput: TagLostStolenInput!
  ) {
    createOrUpdateTagLostStolen(tagLostStolenInput: $tagLostStolenInput) {
      tagId
    }
  }
`;

export const GET_TAG_ACTIVITY_LIST = gql`
  query GetTagActivity(
    $order: OrderBy!
    $filters: [Filter!]!
    $tagNumber: String!
    $tagExpirationDate: Float
  ) {
    getTagActivity(
      order: $order
      filters: $filters
      tagNumber: $tagNumber
      tagExpirationDate: $tagExpirationDate
    ) {
      tagNumber
      aOrB
      expirationDate
      recordedAt
      typeCode
      performedByAgencyCode
      performedByBureauCode
      serialNumberVin
      performedBy
      performedAt
      createdBy
      createdAt
      updatedAt
      performedByUser {
        fullName
        email
      }
      activityType {
        typeCode
        description
      }
    }
  }
`;

export const UPDATE_LP_INFO = gql`
  mutation UpdateLpInfo(
    $tagUuids: [String!]!
    $unicorUuids: [String!]
    $orderNumber: String!
    $expirationDate: Float!
  ) {
    updateLpInfo(
      expirationDate: $expirationDate
      orderNumber: $orderNumber
      unicorUuids: $unicorUuids
      tagUuids: $tagUuids
    ) {
      id
      expirationDate
    }
  }
`;
export const BULK_UPDATE_TAG_CUSTODIAN = gql`
  mutation BulkUpdateTagCustodian($tagCustodianInput: [TagCustodianInput!]!) {
    bulkUpdateTagCustodian(tagCustodianInput: $tagCustodianInput) {
      tagId
    }
  }
`;

export const GET_VEHICLE_LISTING = gql`
  query GetVehiclesListing(
    $appliedFilters: [String!]
    $includeables: [String!]
    $filters: FilterType
    $paginationInput: PaginationArgs
  ) {
    getVehiclesListing(
      appliedFilters: $appliedFilters
      includeables: $includeables
      filters: $filters
      paginationInput: $paginationInput
    ) {
      count
      rows {
        id
        vin
        agencyName
        customerAgencyCode
        customerBureauCode
        lifecycleIndicator
        makeCode
        modelCode
        makeColorName
        modelYear
        tagNumber
        makeName
        modelName
      }
      hasMore
    }
  }
`;

export const USER_VEHICLE_INVENTORY = gql`
  query GetVehiclesByNestedFilters(
    $paginationInput: PaginationArgs
    $filters: FilterType
    $includeables: [String!]
    $appliedFilters: [String!]
  ) {
    getVehiclesByNestedFilters(
      filters: $filters
      paginationInput: $paginationInput
      includeables: $includeables
      appliedFilters: $appliedFilters
    ) {
      rows {
        ...VehicleInventoryRows
      }
      count
      hasMore
    }
  }
  ${VehicleInventoryRows}
`;

export const GET_VEHICLE_EXPANDED_ROW = gql`
  query GetVehicleExpandedRow($vin: String!) {
    getExpandedVehicleRow(vin: $vin) {
      vin
      vehicleStatus
      vehicleColor
      statePlate
      licensePlate
      exemptPlate
      pocName
      pocEmail
      pocPhone
      isRegistered
      requisitionNumber
      mileage
    }
  }
`;

export const EXPORT_USER_VEHICLE_INVENTORY = gql`
  mutation ExportVehiclesByNestedFilters(
    $filters: FilterType
    $order: OrderBy
    $appliedFilters: [String!]
    $includeables: [String!]
  ) {
    exportVehiclesByNestedFilters(
      filters: $filters
      order: $order
      appliedFilters: $appliedFilters
      includeables: $includeables
    )
  }
`;

export const EXPORT_LICENSE_PLATE = gql`
  mutation ExportLicensesByNestedFilters(
    $filters: FilterType
    $order: OrderBy
    $appliedFilters: [String!]
  ) {
    exportLicensesByNestedFilters(
      filters: $filters
      order: $order
      appliedFilters: $appliedFilters
    )
  }
`;

export const EXPORT_LICENCE_PLATE_ORDERS = gql`
  mutation ExportOrdersByNestedFilters(
    $filters: [Filter!]!
    $order: OrderBy!
    $appliedFilters: [String!]
  ) {
    exportOrdersByNestedFilters(
      filters: $filters
      order: $order
      appliedFilters: $appliedFilters
    )
  }
`;

export const GET_FUEL_TYPES = gql`
  query GetFuelNames {
    getFuelNames {
      id
      description
    }
  }
`;

export const DISASSOCIATE_PDF = gql`
  mutation DisassociatePDF(
    $model: String!
    $modelPK: String!
    $documentName: String!
  ) {
    disassociatePDF(
      model: $model
      modelPK: $modelPK
      documentName: $documentName
    )
  }
`;

export const CREATE_SUPPORTING_DOC = gql`
  mutation CreateSupportingDoc(
    $data: JSONObject
    $model: String!
    $modelPK: String!
    $documentName: String!
  ) {
    createSupportingDoc(
      data: $data
      model: $model
      modelPK: $modelPK
      documentName: $documentName
    )
  }
`;

export const GET_COLORS_BY_MAKE = gql`
  query GetColorsByMakeCode($makeCode: Float!) {
    getColorsByMakeCode(makeCode: $makeCode) {
      makeCode
      makeColorCode
      name
    }
  }
`;

export const GET_ORDERS = gql`
  query GetUniqueOrders(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Float!
    $offset: Float!
  ) {
    getUniqueOrders(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      rows {
        orderNumber
        tagNumber
        orderStatusCode
        processedAt
        tagStatus
        pocName
        pocEmail
        pocPhone
        plateCount
        agencyAddress
        createdAt
      }
      count
      hasMore
    }
  }
`;

export const BULK_UPDATE_ORDER = gql`
  mutation Mutation($updateInput: OrderBulkUpdateArgs!) {
    bulkUpdateOrder(updateInput: $updateInput)
  }
`;

export const GET_ORDER = gql`
  query GetOrder($orderNumber: String!) {
    getOrder(orderNumber: $orderNumber) {
      orderStatus
      orderNumber
      originalBureau {
        id
        name
      }
      agency {
        id
        name
      }
      bureau {
        id
        name
      }
      office {
        officeCode
        officeName
      }
      tagNumber
      processedAt
      tagStatus
      pocName
      pocEmail
      pocPhone
      agencyAddress
      tagExpirationDate
      tagExpirationDates
      orderedDate
      shippedDate
      reshippedDate
      updatedBy
      updatedByUser {
        fullName
        email
      }
      updatedAt
      unicorTags {
        rows {
          tagNumber
          tagStatus
          aOrB
          tagExpirationDate
        }
        count
      }
    }
  }
`;

export const GET_USER_BY_KEY = gql`
  query GetUserByKey($key: String!, $value: String!) {
    getUserByKey(key: $key, value: $value) {
      fullName
    }
  }
`;

export const GET_NESTED_LICENSE_PLATES = gql`
  query GetNestedTags(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: FilterType
  ) {
    getNestedTags(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        customerAgencyCode
        customerBureauCode
        customerSubSectionCode
        customerPointOfContactName
        customerPointOfContactEmailAddress
        customerPointOfContactPhone
        customerPointOfContactAddress1
        customerPointOfContactAddress2
        customerPointOfContactAddress3
        customerPointOfContactAddress4
        customerPointOfContactCity
        customerPointOfContactState
        customerPointOfContactZip
        tagActivityTypeCode
        expirationDate
        aOrB
        agency {
          name
          shortName
        }
        bureau {
          name
          shortName
        }
        office {
          officeCode
          officeName
        }
        orderNumber
        VIN
        vehicle {
          id: vin
          modelYear
          modelCode
          vehicleMake {
            makeName
          }
        }
        unicorTag {
          orderNumber
          orderStatus
        }
        createdBy
      }
      count
    }
  }
`;

export const GET_LICENSE_PLATES = gql`
  query GetTags(
    $order: OrderBy!
    $limit: Float!
    $offset: Float!
    $filters: [Filter!]!
  ) {
    getTags(order: $order, limit: $limit, offset: $offset, filters: $filters) {
      rows {
        id
        customerPointOfContactName
        customerPointOfContactEmailAddress
        customerPointOfContactPhone
        customerPointOfContactAddress1
        customerPointOfContactAddress2
        customerPointOfContactAddress3
        customerPointOfContactAddress4
        customerPointOfContactCity
        customerPointOfContactState
        customerPointOfContactZip
        tagActivityTypeCode
        expirationDate
        aOrB
        activity {
          typeCode
          activityType {
            description
          }
        }
        agency {
          name
          shortName
        }
        bureau {
          name
          shortName
        }
        office {
          officeCode
          officeName
        }
        orderNumber
        VIN
        vehicle {
          id
          modelYear
          modelCode
          vehicleMake {
            makeName
          }
        }
        unicorTag {
          orderNumber
          orderStatus
        }
        createdBy
      }
      count
    }
  }
`;
export const ORDER_TAGS = gql`
  query GetOrderTags(
    $filters: [Filter!]!
    $order: OrderBy!
    $limit: Float!
    $offset: Float!
    $orderNumber: String!
  ) {
    getOrderTags(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
      orderNumber: $orderNumber
    ) {
      rows {
        tagNumber
        tagStatus
        aOrB
        tagExpirationDate
        bureau {
          id
          name
        }
        tag {
          tagActivityTypeCode
          id
          aOrB
          expirationDate
        }
        tagByTagNumberAndAorB {
          tagActivityTypeCode
          id
          aOrB
          expirationDate
          VIN
          vehicle {
            vin
          }
        }
        bUnicorTag {
          tagNumber
        }
        activity {
          typeCode
          createdBy
          expirationDate
        }
        updatedBy
      }
      count
    }
  }
`;

export const BULK_TAGS_PENDING_DESTRUCTION = gql`
  mutation BulkTagsPendingDestruction($tags: [TagDestructionArgs!]!) {
    bulkTagsPendingDestruction(tags: $tags) {
      id
      expirationDate
      agency {
        shortName
      }
      aOrB
      tagActivityTypeCode
    }
  }
`;

export const RECONCILE_ORDER_TAGS = gql`
  mutation ReconcileOrderTags($orderNumber: String!, $comment: String) {
    reconcileOrderTags(orderNumber: $orderNumber, comment: $comment) {
      tagStatus
      unicorTags {
        rows {
          tagNumber
          tagStatus
          aOrB
          tagExpirationDate
        }
        count
      }
    }
  }
`;

export const VALIDATE_ADDRESS = gql`
  query ValidateAddress(
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    validateAddress(
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
    ) {
      address
      address2
      city
      state
      zip
      updates
    }
  }
`;

export const RECONCILE_ORDER_TAG = gql`
  mutation ReconcileOrderTag(
    $tagNumber: String!
    $aOrB: String!
    $tagExpirationDate: Float!
  ) {
    reconcileOrderTag(
      tagNumber: $tagNumber
      aOrB: $aOrB
      tagExpirationDate: $tagExpirationDate
    ) {
      tagNumber
      tagStatus
      aOrB
      tagExpirationDate
    }
  }
`;

export const RECONCILE_TAGS = gql`
  mutation ReconcileTags($tagNumber: String!, $tagExpirationDate: Float!) {
    reconcileTags(
      tagNumber: $tagNumber
      tagExpirationDate: $tagExpirationDate
    ) {
      reconciledTags {
        id
        aOrB
        tagActivityTypeCode
        expirationDate
      }
      reconciledUnicorTags {
        tagNumber
        aOrB
        tagStatus
        tagExpirationDate
      }
    }
  }
`;

export const REPORT_LOST_STOLEN_TAGS = gql`
  mutation ReportLostStolenTags(
    $tagNumber: String!
    $tagExpirationDate: Float!
  ) {
    reportLostStolenTags(
      tagNumber: $tagNumber
      tagExpirationDate: $tagExpirationDate
    ) {
      reportedTags {
        id
        aOrB
        tagActivityTypeCode
        expirationDate
        vehicle {
          id
        }
      }
      reportedUnicorTags {
        tagNumber
        aOrB
        tagStatus
        tagExpirationDate
      }
    }
  }
`;

export const EXPORT_TAGS = gql`
  query ExportTags($filters: [Filter!]!, $order: OrderBy!) {
    exportTags(filters: $filters, order: $order) {
      filename
      downloadURL
    }
  }
`;

export const SAP_ORDER_CREATE_VIA_URL = gql`
  mutation createUnicortagsUsingUrl(
    $metadataId: String!
    $url: String!
    $fileName: String!
  ) {
    createUnicortagsUsingUrl(
      metadataId: $metadataId
      url: $url
      fileName: $fileName
    )
  }
`;

export const UPLOAD_DOC = gql`
  mutation UploadDocument($input: DocInput!) {
    uploadDocument(input: $input) {
      id
      metadataId
      metadata {
        name
        docSource
        docStoreUri
        fileLocation
        fileMimeType
        scanStatus
        size
        uploadCompletedAt
        creator {
          fullName
        }
      }
      signedUrl
    }
  }
`;

export const UPLOAD_SUPPORTING_DOC = gql`
  mutation UploadSupportingVMSDocument($input: DocInput!) {
    uploadSupportingVMSDocument(input: $input) {
      id
      metadataId
      metadata {
        name
        description
        docSource
        docStoreUri
        fileLocation
        fileMimeType
        scanStatus
        size
        uploadCompletedAt
        creator {
          fullName
        }
      }
      signedUrl
    }
  }
`;

export const BULK_REGISTRATION_PDF = gql`
  mutation GetVehiclesAndCreateBulkVehiclePDFRegistrations(
    $vehicles: [String!]!
  ) {
    getVehiclesAndCreateBulkVehiclePDFRegistrations(vehicles: $vehicles)
  }
`;

export const CREATE_TAG_DESTRUCTION = gql`
  mutation CreateTagDestructions($data: JSONObject, $tags: [String!]!) {
    createTagDestructions(data: $data, tags: $tags)
  }
`;

export const CREATE_READ_SIGNED_URL = gql`
  mutation CreateReadSignedUrl($metadataId: String!) {
    createReadSignedUrl(metadataId: $metadataId)
  }
`;

export const GET_CURRENT_USER = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      fullName
      addrline1
      addrline2
      addrline3
      city
      state
      postalCode
      phoneNumber
      phoneNumberExt
      customerAttrs {
        registeredAgencyCode
        registeredBureauCode
        registeredOfficeCode
      }
      internalAttrs {
        userId
        zone {
          id
          name
        }
        managementCenter {
          id
          name
        }
      }
    }
  }
`;

export const GET_MODELS_BY_PARTIAL_NAME = gql`
  query ($modelDescription: String, $makeCode: Float) {
    getModelsByPartialModelName(
      modelDescription: $modelDescription
      makeCode: $makeCode
    ) {
      makeCode
      modelCode
      modelDescription: modelName
    }
  }
`;

export const GET_MAKES_BY_PARTIAL_NAME = gql`
  query ($makeName: String!) {
    getMakesByPartialName(makeName: $makeName) {
      makeName
      makeCode
    }
  }
`;

export const UPDATE_VEHICLE_POC = gql`
  mutation updateVehiclePoC(
    $poc: PointOfContactArgs!
    $pocNumber: Float!
    $vin: String!
  ) {
    updateVehiclePoC(vin: $vin, pocNumber: $pocNumber, poc: $poc) {
      ...VehicleFieldsFull
    }
  }
  ${VehicleFieldsFull}
`;

export const UPDATE_VEHICLE_AGENCY_DETAILS = gql`
  mutation UpdateVehicleAgencyDetails(
    $agencyDetails: AgencyDetailsArgs!
    $vin: String!
  ) {
    updateVehicleAgencyDetails(vin: $vin, agencyDetails: $agencyDetails) {
      id
    }
  }
`;

export const UPDATE_VEHICLE_FIELDS = gql`
  mutation UpdateVehicleField($vin: String!, $fields: [VehicleField!]!) {
    updateVehicleField(vin: $vin, fields: $fields) {
      id
      modelYear
      makeCode
      modelCode
      makeColorName
      vehicleTrim
      vehicleSeries
      fuelCode
      fastReportable
      itemInventoryStatusCode
      assetUsage {
        assetId
        vamExempt
        executiveFleet
        agencyUse1
        agencyUse2
        agencyUse3
        agencyUse4
      }
    }
  }
`;

export const UPDATE_STATE_PLATE = gql`
  mutation UpdateStatePlate($updateStatePlate: UpdateStatePlateInput!) {
    updateStatePlate(updateStatePlate: $updateStatePlate) {
      stateTag
      stateTagExpirationDate
    }
  }
`;

export const SWAP_TAG = gql`
  mutation SwapTag(
    $tagNumber: String!
    $tagExpirationDate: Float!
    $newTagExpirationDate: Float!
    $vin: String!
  ) {
    swapTag(
      tagNumber: $tagNumber
      tagExpirationDate: $tagExpirationDate
      newTagExpirationDate: $newTagExpirationDate
      vin: $vin
    ) {
      id
      expirationDate
      tagActivityTypeCode
    }
  }
`;

export const SAP_UPLOAD = gql`
  mutation SapUpload($filename: [String!]!, $file: [String!]!) {
    sapUpload(file: $file, filename: $filename)
  }
`;

// Vehicle Recalls Details
export const GET_RECALLS_BY_VEHICLE = gql`
  query getRecallsByVehicle(
    $id: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
  ) {
    getRecallsByVehicle(
      id: $id
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        recallCampaignId
        assetId
        status
        userCloseStatus
        closedDate
        userClosedDate
        gsaRecallNotifiedDate
        closedByUserInfo {
          fullName
          email
        }
        createdAt
        updatedBy
        updatedAt
        deletedAt
        recallCampaign {
          id
          recallCode
          recallSource
          recallType
          makeCode
          modelCode
          modelYear
          recallReleaseDate
          gsaNotifiedDate
          recallUrl
          isRemedyAvailable
          remedyDescription
          shouldParkOutside
          shouldStopDrive
          nhtsaCampaignId
          recallType
          recallDescription
          detailDescription
          safetyRisk
          remedyStatus
          createdAt
          updatedBy
          updatedAt
          deletedAt
        }
        assetRecallComments {
          id
          assetRecallId
          comment
          createdBy
          createdAt
          updatedBy
          updatedAt
          deletedAt
          createdByUser {
            fullName
            email
          }
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_VEHICLES_BY_PARTIAL_TAG = gql`
  query GetVehiclesByPartialTag($partialTag: String!) {
    getVehiclesByPartialTag(partialTag: $partialTag) {
      id: vin
      makeColor {
        name
      }
      modelYear
      tagNumber
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
    }
  }
`;

export const GET_VEHICLES_BY_PARTIAL_VIN = gql`
  query GetVehiclesByPartialVin($partialVin: String!) {
    getVehiclesByPartialVin(partialVin: $partialVin) {
      id: vin
      makeColor {
        name
      }
      modelYear
      tagNumber
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
    }
  }
`;

export const MATCHING_VEHICLES_BY_PARTIAL_VIN = gql`
  query GetMatchingVinsByPartialVin(
    $partialVin: String!
    $agency: String!
    $bureau: String
    $office: String
    $vehicleOption: String!
  ) {
    getMatchingVinsByPartialVin(
      partialVin: $partialVin
      agency: $agency
      bureau: $bureau
      office: $office
      vehicleOption: $vehicleOption
    ) {
      id: vin
      ownershipTypeCode
      makeColor {
        name
      }
      modelYear
      tagNumber
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
    }
  }
`;

export const MATCHING_VEHICLES_BY_PARTIAL_TAG = gql`
  query getMatchingVinsByPartialTag(
    $partialTag: String!
    $agency: String!
    $bureau: String
    $office: String
    $vehicleOption: String!
  ) {
    getMatchingVinsByPartialTag(
      partialTag: $partialTag
      agency: $agency
      bureau: $bureau
      office: $office
      vehicleOption: $vehicleOption
    ) {
      id: vin
      ownershipTypeCode
      makeColor {
        name
      }
      modelYear
      tagNumber
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
    }
  }
`;

export const GET_TAGS_BY_PARTIAL_TAG_NUMBER = gql`
  query GetTagsByPartialTagNumber($partialTagNumber: String!) {
    getTagsByPartialTagNumber(partialTagNumber: $partialTagNumber) {
      id
    }
  }
`;

export const GET_TAGS_BY_FOR_VEHICLE_PLATE_CHANGE = gql`
  query GetTagsForVehiclePlateChange($partialTagNumber: String!) {
    getTagsForVehiclePlateChange(partialTagNumber: $partialTagNumber) {
      id
    }
  }
`;

export const GET_FC_ORDERS_BY_PARTIAL_TAG_NUMBER = gql`
  query GetFcReplacementOrdersByPartialTag($partialTagNumber: String!) {
    getFcReplacementOrdersByPartialTag(partialTagNumber: $partialTagNumber) {
      tagNumber
    }
  }
`;
// NEED TO
export const GET_RECALL_CAMPAIGN_OPTIONS = gql`
  query GetRecallCampaigns(
    $order: OrderBy
    $limit: Float
    $filters: [Filter!]!
    $distinctField: String
  ) {
    getRecallCampaigns(
      order: $order
      limit: $limit
      filters: $filters
      distinctField: $distinctField
    ) {
      recallCodes
      nhtsaCampaignIds
    }
  }
`;

export const GET_RECALLS = gql`
  query getRecalls(
    $offset: Float
    $limit: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getRecalls(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        recallCampaignId
        assetId
        status
        userClosedDate
        gsaRecallNotifiedDate
        closedDate
        vehicle {
          id
          vin
          tagNumber
          ownershipTypeCode
          customer {
            customerAgencyCode
            customerBureauCode
            customerOfficeCode
            legacyCustomerNumber
          }
        }
        recallCampaign {
          id
          recallCode
          recallReleaseDate
          recallDescription
          detailDescription
          safetyRisk
          nhtsaCampaignId
          isRemedyAvailable
          remedyDescription
          recallUrl
          gsaNotifiedDate
          shouldParkOutside
          shouldStopDrive
        }
      }
      count
      hasMore
    }
  }
`;

export const ADD_ODOMETER_READING = gql`
  mutation addOdometerReading($odometerInput: OdometerInput!) {
    addOdometerReading(odometerInput: $odometerInput)
  }
`;

export const EXPORT_RECALLS = gql`
  query exportRecalls($filters: [Filter!]!) {
    exportRecalls(filters: $filters)
  }
`;

export const GET_ALD = gql`
  query getAld($vin: String!) {
    getAld(vin: $vin) {
      assetId
      lawEnforcement
      isEmergencyResponse
      leaseCost
      assignmentType
      reportOrganization
      agencyBudgetElementCode
      agencySort
      isLocationWithheld
      garageDomestic
      garageAddress1
      garageAddress2
      garageAddress3
      garageCity
      garageStateCode
      garagePostalCode
      garageCountryCode
      isGasAfv
      aldCoverageDesignation
      ald2005701
      ald141Designation
      aldComment
      fastDescription
      leaseType
      indirectCost
      leaseCost
      accidentRepairCost
      armoredDesignation
      isMsa
      stateTag
      stateTagExpirationDate
      homeToWork
      assetAcquisition {
        acquisitionCost
        originalAcquisitionDate
      }
      assetTelematicsMetadata {
        telematicsDataUsage
      }
      vehicle {
        cddTelematicsInstalled
      }
    }
  }
`;

export const GET_ALD_CATEGORIES = gql`
  query getAldCategories {
    getAldCategories {
      name
      description
      options {
        code
        description
      }
    }
  }
`;

export const UPDATE_ALD = gql`
  mutation UpdateAld($updateAld: AldInput!) {
    updateAld(updateAld: $updateAld) {
      ... on Ald {
        assetId
      }
      ... on AldUpdateError {
        errors {
          code
          message
          fields
        }
      }
    }
  }
`;

export const GET_VMS_ENABLED_FEATURES = gql`
  query getVMSEnabledFeatures {
    getVMSEnabledFeatures
  }
`;

// for the mileage history table
export const GET_MILEAGE_HISTORY = gql`
  query getMileageHistory(
    $offset: Float
    $limit: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getMileageHistory(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      count
      hasMore
      rows {
        id
        assetId
        mileageDate
        daysInUse
        odometer
        sourceType
        createdBy
        updatedBy
        updatedAt
        createdAt
        vehicle {
          id
          vin
          tagNumber
          agencyCode
          bureauCode
          officeCode
        }
      }
    }
  }
`;

// for the mileage history table
export const GET_MILEAGE_HISTORY_VIEW_DATA = gql`
  query getMileageHistoryViewData(
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
    $key: String!
    $value: String!
  ) {
    getAgency(id: $agencyCode) {
      name: prefixedName
    }
    getUserByKey(key: $key, value: $value) {
      fullName
      email
    }
    getBureau(id: $bureauCode, agencyCode: $agencyCode) {
      name: prefixedName
    }
    getOfficeNext(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      name: prefixedName
    }
  }
`;

export const EXPORT_VEHICLES_MILEAGE = gql`
  query exportVehicleMileages($filters: [Filter!]!) {
    exportVehicleMileages(filters: $filters)
  }
`;

export const EXPORT_EXPENSES = gql`
  query exportFleetCardTransaction($filters: [Filter!]!) {
    exportFleetCardTransaction(filters: $filters)
  }
`;

export const EXPORT_REPORT = gql`
  mutation GenerateReport($request: ReportRequestInput!) {
    generateReport(reportRequest: $request) {
      id
      reportStatus
      reportDeliveryMethod
      reportType
    }
  }
`;

export const CREATE_REPORT_SCHEDULES = gql`
  mutation CreateReportSchedule($reportScheduleInput: ReportScheduleInput!) {
    createReportSchedule(reportScheduleInput: $reportScheduleInput) {
      reportTypeId
      reportName
    }
  }
`;

export const UPDATE_REPORT_SCHEDULES = gql`
  mutation UpdateReportSchedule($updateScheduleInput: UpdateScheduleInput!) {
    updateReportSchedule(updateScheduleInput: $updateScheduleInput) {
      reportTypeId
      reportName
    }
  }
`;

export const GET_ZONAL_REPORTS = gql`
  query GetZonalReports($zone: String!) {
    getZonalReports(zone: $zone) {
      fileName
      fileType
      fileKey
    }
  }
`;

export const GET_FLEET_CARD_TRANSACTIONS = gql`
  query getFleetCardTransactions(
    $offset: Float
    $limit: Float
    $order: OrderBy
    $filters: [Filter!]!
  ) {
    getFleetCardTransactions(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        assetId
        grossCost
        transactionDate
        ccn4
        vehicle {
          tagNumber
          vin
          modelYear
          modelCode
          vehicleMake {
            makeName
          }
        }
        fleetCardProduct {
          id
          productCode
          productName
          productDescription
          productCategory
        }
        fleetCardProductOnline {
          id
          productCode
          productName
          productDescription
          productCategory
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_AL_CATEGORY = gql`
  query getALByCategories($lookupCategories: [String!]!) {
    getALByCategories(lookupCategories: $lookupCategories) {
      name
      description
      options {
        code
        description
      }
    }
  }
`;

export const IMPORT_BULK_RECALL_VINS = gql`
  mutation ImportBulkRecallVins($importInput: [BulkRecallVinsInput!]!) {
    importBulkRecallVins(importInput: $importInput) {
      ... on BulkUpdateResponse {
        vin
      }
      ... on BulkUpdateError {
        errorResponse {
          validationErrors {
            errors {
              code
              message
              fields
            }
          }
          vin
        }
      }
    }
  }
`;

export const CREATE_VEHICLE_ACCESSORY = gql`
  mutation CreateVehicleAccessory($id: String!, $accessories: [String!]!) {
    createVehicleAccessory(id: $id, accessories: $accessories) {
      ...VehicleFieldsFull
    }
  }
  ${VehicleFieldsFull}
`;

export const UPDATE_VEHICLE_ACCESSORY = gql`
  mutation UpdateVehicleAccessory($id: String!, $accessories: [String!]!) {
    updateVehicleAccessory(id: $id, accessories: $accessories) {
      ...VehicleFieldsFull
    }
  }
  ${VehicleFieldsFull}
`;

export const GET_STATES = gql`
  query getStates {
    states: getStates {
      stateCode
    }
  }
`;

export const GET_TAGS_BY_EXPIRATION_DATE = gql`
  query GetTagsByExpirationDate($input: [TagListInput!]!) {
    getTagsByExpirationDate(input: $input) {
      VIN
      aOrB

      id
      agency {
        id
        shortName
        name
      }
      bureau {
        id
        shortName
        name
      }
      createdBy
      customerAgencyCode
      customerBureauCode
      customerPointOfContactAddress1
      customerPointOfContactAddress2
      customerPointOfContactAddress3
      customerPointOfContactAddress4
      customerPointOfContactEmailAddress
      customerPointOfContactName
      customerPointOfContactPhone
      customerPointOfContactState
      customerPointOfContactZip
      customerSubSectionCode
      expirationDate
      office {
        officeCode
        agencyCode
        bureauCode
      }
      orderNumber
      tagActivityTypeCode
      unicorTag {
        unicorTags {
          rows {
            orderNumber
            orderStatus
          }
        }
      }
    }
  }
`;

export const SEND_RECALL_NOTIFICATION_EMAIL = gql`
  mutation SendVehicleRecallNotificationEmail($campaignId: String) {
    sendVehicleRecallNotificationEmail(campaignId: $campaignId)
  }
`;

export const CREATE_RECALL = gql`
  mutation CreateRecall($recallInput: RecallInput!) {
    createRecall(recallInput: $recallInput) {
      id
    }
  }
`;

export const UPDATE_RECALL_CAMPAIGN = gql`
  mutation UpdateRecallCampaign($recallInput: RecallInput!) {
    updateRecallCampaign(recallInput: $recallInput) {
      id
    }
  }
`;

export const UPDATE_ALD_CUSTOMER_ACCOUNT = gql`
  mutation UpdateAldAccountInformation(
    $updateAldInput: AldInputCustomerAccount!
  ) {
    updateAldAccountInformation(updateAldInput: $updateAldInput)
  }
`;

export const GENERATE_READ_ONLY_SIGNED_URL = gql`
  query GenerateReadonlySignedURL(
    $bucket: String!
    $fileKey: String!
    $fileType: String
    $duration: Float
  ) {
    generateReadonlySignedURL(
      bucket: $bucket
      fileKey: $fileKey
      fileType: $fileType
      duration: $duration
    )
  }
`;

export const GET_DISTINCT_STANDARD_ITEMS = gql`
  query GetDistinctStandardItems($year: Float) {
    getDistinctStandardItems(year: $year) {
      standardItemNumber
      title
    }
  }
`;
