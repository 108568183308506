import { gql } from '@apollo/client';

export const GET_PM_BY_VEHICLE = gql`
  query getPmByVehicle(
    $assetId: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]
  ) {
    getPmByVehicle(
      assetId: $assetId
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        assetPmId
        pmSchedule {
          pmScheduleId
          pmMilesInterval
          pmKilometersInterval
          pmMonthsInterval
          pmScheduleNumber
          pmTitle
          pmAdditionalNotes
          pmRequiredService
        }
        customer {
          legacyCustomerNumber
        }
        invoiceData {
          docStoreUri
          name
          fileLocation
          docMetaId
          fileType
        }

        nextPmMileage
        nextPmDate
        currentPmMileage
        currentPmDate
        customerId
        pmStatus
        methodOfEntry
        pmScheduleNumber
        createdByUser {
          fullName
        }
        createdAt
        updatedBy
        updatedAt
      }
      count
      hasMore
    }
  }
`;

export const GET_PM_SCHEDULES = gql`
  query GetPreventativeMaintenanceSchedules(
    $pmScheduleId: String
    $vehicleLocation: String!
    $filters: [Filter!]
  ) {
    getPreventativeMaintenanceSchedules(
      pmScheduleId: $pmScheduleId
      vehicleLocation: $vehicleLocation
      filters: $filters
    ) {
      pmScheduleId
      pmScheduleNumber
      pmTitle
      pmLocation
    }
  }
`;

export const UPDATE_ASSET_PM_SCHEDULE = gql`
  mutation UpdateAssetPmSchedule(
    $updatePmScheduleFields: UpdatePmScheduleInput!
  ) {
    updateAssetPmSchedule(updatePmScheduleFields: $updatePmScheduleFields)
  }
`;

export const DELETE_PM = gql`
  mutation DeletePm($deletePmFields: DeletePmInput!) {
    deletePm(deletePmFields: $deletePmFields)
  }
`;
