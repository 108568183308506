/* eslint-disable react/prop-types */
import {
  Button,
  RequiredFieldIndicator,
  Modal,
  Alert,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { FormGenerator, SelectControl } from '@gsa/afp-shared-form-utils';
import FormattedNumberControl from 'utilities/form-generator-controls/formatted-number-control';
import {
  GET_VEHICLE_EDIT_OPTIONS,
  GET_MAKE_OPTIONS,
} from 'services/data-layer';
import { FG_DEFAULT_OPTION_VAL } from 'utilities/consts';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
  isSiteAdmin,
} from 'utilities/authorization';
import {
  getOptionsWithDefault,
  boolFieldToYesOrNo,
  yesOrNoOptions,
} from 'utilities/form-generator-controls/helper';
import moment from 'moment';
import {
  getAssignmentDateFromLifecycle,
  validateStatePlateExp,
  formatStatePlateExp,
} from 'utilities/common';
import { getVINVerificationStatus } from '../helpers/vehicle-details-helper';

export default function GFVehicleEdit({
  onClose,
  vehicle,
  onSave,
  isGFModalOpen,
  errorMessage,
}) {
  const ability = useAppAbility();
  const assignmentDate = getAssignmentDateFromLifecycle(
    vehicle?.assetLifecycle,
  );

  const schema = z.object({
    makeCode: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    modelCode: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    makeColorName: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    fuelCode: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    tankSize: z.string().nullish(),
    modelYear: z.string().nullish(),
    color: z.string().nullish(),
    fastReportable: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    gvwr: z.string().nullish(),
    licensePlate: z.string().nullish(),
    stateTag: z.string().nullish(),
    stateTagExpirationDate: z
      .string()
      .refine(
        (expDate) => {
          const validate = validateStatePlateExp(expDate);
          return !(validate && validate?.message);
        },
        {
          message: 'Please enter a valid date and required format is MM/YYYY.',
        },
      )
      .nullable(),
    afiNumber: z.string().nullish(),
  });

  const [fuelCode, setFuelCode] = useState();
  const [modelCode, setModelCode] = useState();
  const [colorOptionsState, setColorOptions] = useState([]);
  const [fuelOptionsState, setFuelOptionsState] = useState([]);
  const [makeOptionsState, setMakeOptionsState] = useState([]);
  const [modelOptionsState, setModelOptionsState] = useState([]);
  const [initialModelOptionsState, setInitialModelOptionsState] = useState([]);
  const [makeLoading, setMakeLoading] = useState(true);
  const [vehicleEditOptionsLoading, setVehicleEditOptionsLoading] =
    useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [alertContent, setAlertContent] = useState({
    showAlert: false,
    type: '',
    message: '',
  });

  const isLoading = makeLoading || vehicleEditOptionsLoading;

  useEffect(() => {
    if (errorMessage && errorMessage.fuelError) {
      setAlertContent({
        showAlert: true,
        type: 'error',
        message: errorMessage?.fuelError,
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    getVehicleMakeModelOptions();
  }, []);

  const [getVehicleEditOptions, { loading: editOptionsLoading }] = useLazyQuery(
    GET_VEHICLE_EDIT_OPTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setModelOptionsState(
          data?.getVehicleMakeModels?.models.map((option) => ({
            label: option.modelDescription,
            value: option.modelCode,
          })),
        );
        setColorOptions(
          data?.getVehicleMakeModels?.colors.map((option) => ({
            label: option.name,
            value: option.makeColorCode,
          })),
        );
        setFuelOptionsState(
          data?.getFuelNames?.map((option) => ({
            label: option.description,
            value: option.id,
          })),
        );
        setVehicleEditOptionsLoading(false);
      },
      onError: () => {
        setVehicleEditOptionsLoading(false);
      },
    },
  );

  const handleClose = () => {
    setIsFirstLoad(true);
    setFuelCode(vehicle?.fuelCode?.toString());
    setModelCode(vehicle?.vehicleModel?.modelCode);
    setModelOptionsState(initialModelOptionsState);
    setAlertContent({
      showAlert: false,
      type: '',
      message: '',
    });
    onClose();
  };

  const [getVehicleMakeModelOptions] = useLazyQuery(GET_MAKE_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setMakeOptionsState(
        data?.getMakeNames?.map((option) => ({
          label: option.makeName,
          value: option.makeCode,
        })),
      );
      setMakeLoading(false);
    },
    onError: () => {
      setMakeLoading(false);
    },
  });

  useEffect(() => {
    if (vehicle?.vehicleMake?.makeName) {
      getVehicleEditOptions({
        variables: { makeName: vehicle?.vehicleMake?.makeName },
      });
    }
  }, [vehicle?.vehicleMake?.makeName]);

  useEffect(() => {
    if (!isGFModalOpen && modelOptionsState.length > 0) {
      setInitialModelOptionsState(modelOptionsState);
    }
  }, [isGFModalOpen, modelOptionsState, initialModelOptionsState]);

  const whoCanEdit = (editType) => {
    if (editType === 'admin') {
      return !canUpdateGFVehicleAdmin(ability);
    }
    if (editType === 'fsr') {
      return !canUpdateGFVehicleFSR(ability);
    }
    return true;
  };

  const isSiteAdminUser = isSiteAdmin(ability);
  const isEditAllowed = whoCanEdit('admin') || !isSiteAdmin(ability);

  const data = {
    assignmentDate: assignmentDate
      ? moment.utc(assignmentDate).format('MM/DD/YYYY')
      : '',
    vinModify: vehicle.id,
    vinVerifiedBy: getVINVerificationStatus(
      vehicle.pre1981,
      vehicle.internationalVin,
      vehicle.unverifiedVin,
      vehicle.nhtsaVerified,
    ),
    vehicleOdometer: vehicle?.newOdometerReading?.odometer
      ? vehicle.newOdometerReading?.odometer
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : vehicle?.assetAcquisition?.deliveredMiles || '',
    modelYear: vehicle?.modelYear ? vehicle?.modelYear.toString() : '',
    makeCode: vehicle?.vehicleMake?.makeCode || FG_DEFAULT_OPTION_VAL,
    modelCode:
      modelCode || vehicle?.vehicleModel?.modelCode || FG_DEFAULT_OPTION_VAL,
    vehicleSeries: vehicle?.vehicleSeries,
    vehicleTrim: vehicle?.vehicleTrim,
    vehicleGroup: vehicle.itemType,
    makeColorName: vehicle?.makeColorName ?? FG_DEFAULT_OPTION_VAL,
    fuelCode:
      fuelCode || vehicle?.fuelCode?.toString() || FG_DEFAULT_OPTION_VAL,
    tankSize: vehicle?.assetTechnical?.assetTechnicalIce?.tankSize
      ? vehicle?.assetTechnical?.assetTechnicalIce?.tankSize.toString()
      : '',
    fastReportable: boolFieldToYesOrNo({
      data: vehicle,
      field: 'fastReportable',
    }),
    gvwr: vehicle?.assetTechnical?.gvwr
      ? vehicle?.assetTechnical?.gvwr.toString()
      : '',
    licensePlate: vehicle?.tagNumber,
    stateTag: vehicle?.ald?.stateTag,
    stateTagExpirationDate: vehicle?.ald?.stateTagExpirationDate
      ? formatStatePlateExp(vehicle?.ald?.stateTagExpirationDate)
      : '',
    afiNumber: vehicle?.ald?.afiNumber,
  };

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'assignmentDate',
            label: 'Assignment date',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vinModify',
            label: 'Vin',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'vinVerifiedBy',
            label: 'VIN verified by',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vehicleOdometer',
            label: 'Vehicle odometer',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'modelYear',
            label: 'Year',
            type: 'text',
            disabled: !isSiteAdminUser,
          },
          {
            id: 'makeCode',
            label: 'Make',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(makeOptionsState),
            disabled: !isSiteAdminUser,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'modelCode',
            label: 'Model',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(modelOptionsState),
            component: (props, useFormContext) => {
              const { watch, setValue, setError, clearErrors } =
                useFormContext();
              const currentMake = watch('makeCode');
              useEffect(() => {
                if (isFirstLoad) {
                  setIsFirstLoad(false);
                  if (modelOptionsState.length > 0) {
                    setInitialModelOptionsState([]);
                    setInitialModelOptionsState(modelOptionsState);
                  }
                  return;
                }
                setValue('modelCode', FG_DEFAULT_OPTION_VAL);
                const selectedMake = makeOptionsState.find(
                  (option) => option.value === currentMake,
                );
                const makeName = selectedMake ? selectedMake.label : null;
                if (makeName && currentMake !== FG_DEFAULT_OPTION_VAL) {
                  getVehicleEditOptions({ variables: { makeName } });
                  clearErrors('makeCode');
                } else if (currentMake === FG_DEFAULT_OPTION_VAL) {
                  setModelOptionsState([]);
                  setError('makeCode', {
                    type: 'manual',
                    message: 'This is required',
                  });
                }
              }, [currentMake]);
              return (
                <div
                  style={{ display: 'flex', gap: '12px', alignItems: 'center' }}
                >
                  {SelectControl(
                    {
                      ...props,
                      disabled:
                        !isSiteAdminUser ||
                        editOptionsLoading ||
                        modelOptionsState.length === 0,
                      className: [],
                    },
                    {
                      id: props.id,
                      options: getOptionsWithDefault(modelOptionsState),
                    },
                  )}
                  {editOptionsLoading && <Spinner size="small" />}
                </div>
              );
            },
          },
          {
            id: 'vehicleSeries',
            label: 'Vehicle series',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'vehicleTrim',
            label: 'Trim',
            type: 'text',
            disabled: true,
          },
          {
            id: 'vehicleGroup',
            label: 'Vehicle group',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'makeColorName',
            label: 'Color',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(colorOptionsState),
            disabled: isEditAllowed,
          },
          {
            id: 'fuelCode',
            label: 'Fuel type',
            type: 'select',
            required: true,
            options: getOptionsWithDefault(fuelOptionsState),
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'tankSize',
            label: 'Fuel tank capacity',
            type: 'format-number',
            element: {
              control: { decimalScale: 4, suffix: ' gal' },
            },
          },
          {
            id: 'fastReportable',
            label: 'FAST reportable',
            type: 'select',
            required: true,
            options: yesOrNoOptions,
            disabled: whoCanEdit(ability, 'admin'),
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'gvwr',
            label: 'GVWR',
            type: 'format-number',
            element: {
              control: { decimalScale: 0, suffix: ' lb' },
            },
            disabled: whoCanEdit(ability, 'admin'),
          },
          {
            id: 'licensePlate',
            label: 'License plate',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'stateTag',
            label: 'State plate',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
          },
          {
            id: 'stateTagExpirationDate',
            label: 'State plate expiration date',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
            help: {
              after: 'Format date as MM/YYYY',
            },
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'afiNumber',
            label: 'AFI number',
            type: 'text',
            element: { control: { maxLength: 20, countdown: true } },
          },
          {
            type: 'spacer',
          },
        ],
      },
    ],
  };

  return (
    isGFModalOpen && (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            variant="large"
            title={<h2>Edit vehicle details information</h2>}
            onClose={handleClose}
            showAlert={alertContent.showAlert}
            alert={
              <Alert type={alertContent.type} slim>
                {alertContent.message}
              </Alert>
            }
            actions={
              <>
                <Button
                  variant="unstyled"
                  onClick={handleClose}
                  label="Close"
                />
                {!isLoading && (
                  <Button
                    className="margin-left-2"
                    type="submit"
                    form="g-vehicle-edit-form"
                    label="Save and close"
                  />
                )}
              </>
            }
          >
            {isLoading ? (
              <Spinner size="medium" />
            ) : (
              <>
                <p>
                  Edit vehicle details information for VIN{' '}
                  <span className="text-bold">{vehicle.id}</span> in the form
                  below.
                </p>
                <p>
                  Required fields are marked with an asterisk (
                  <RequiredFieldIndicator />
                  ).
                </p>
                <FormGenerator
                  id="g-vehicle-edit-form"
                  schema={schema}
                  content={content}
                  defaultValues={data}
                  controls={{ 'format-number': FormattedNumberControl }}
                  onSubmit={onSave}
                  onChange={[
                    ['fuelCode'],
                    (methods) => {
                      if (methods.watch('fuelCode') !== data.fuelCode) {
                        if (
                          methods.watch('fuelCode') !== FG_DEFAULT_OPTION_VAL
                        ) {
                          setFuelCode(methods.watch('fuelCode'));
                        }
                        setAlertContent({
                          showAlert: true,
                          type: 'warning',
                          message:
                            'Changing fuel type will change the PM schedule.',
                        });
                      } else {
                        setAlertContent({
                          showAlert: false,
                          type: '',
                          message: '',
                        });
                      }
                    },
                  ]}
                />
              </>
            )}
          </Modal>
        </div>
      </div>
    )
  );
}
